<template>
  <section class="flex items-center w-full overflow-hidden carousel">
    <slot v-if="!items || items.length === 0" name="empty">No items</slot>
    <template v-else>
      <slot v-if="hooperSettings.infiniteScroll" name="navLeft">
        <div class="mr-2">
          <icon
            :icon="angleDoubleLeftIcon"
            class="cursor-pointer text-blue"
            @click="slidePrev"
          />
        </div>
      </slot>

      <hooper
        ref="carousel"
        :settings="hooperSettings"
        class="overflow-hidden"
        @slide="$emit('slide', $event)"
      >
        <slide
          v-for="(item, index) in items"
          :key="`carousel-${item.id || item.name || item.value || item.text}`"
        >
          <slot :item="item" :index="index" />
        </slide>
      </hooper>

      <slot v-if="hooperSettings.infiniteScroll" name="navRight">
        <div class="ml-2">
          <icon
            :icon="angleDoubleRightIcon"
            class="cursor-pointer text-blue"
            @click="slideNext"
          />
        </div>
      </slot>
    </template>
  </section>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import {
  angleDoubleLeft as angleDoubleLeftIcon,
  angleDoubleRight as angleDoubleRightIcon
} from '@/vendor/icons';

export default {
  components: {
    Hooper,
    Slide
  },
  props: {
    settings: { type: Object, default: null },
    items: { type: Array, default: null }
  },
  data() {
    return {
      angleDoubleLeftIcon,
      angleDoubleRightIcon
    };
  },
  computed: {
    hooperSettings() {
      return { ...this.settings, wheelControl: false };
    }
  },
  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    }
  }
};
</script>

<style lang="scss" scoped>
.carousel {
  margin: -2px;
  padding: 2px;
}

/deep/ {
  .hooper,
  .hooper-list {
    margin: -2px;
    padding: 2px;
    height: initial;
  }

  .hooper {
    height: initial;
  }

  .hooper,
  .hooper-list,
  .hooper-track {
    outline: none;
  }
}
</style>
