<script>
import {
  GetAdHistory,
  GetSupplierAdHistory
} from '@/graphql/ads/adHistory.graphql';

import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    ad: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: () => ({
        fetchPolicy: 'no-cache'
      })
    }
  },

  query(props) {
    return {
      query: this.$can('manage_non_account_ads')
        ? GetAdHistory
        : GetSupplierAdHistory,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        id: this.ad.id
      }
    };
  }
};
</script>
