<template>
  <el-dialog
    :title="theTitle"
    :visible="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    class="supplier-acceptance-dialog"
    @update:visible="$event || $emit('close')"
    @close="$emit('close')"
  >
    <transition name="fadeIn" mode="out-in">
      <template v-if="isApprovingPaymentMethod">
        <div>
          <payment-method-approval
            :order="orderToApprove"
            :campaign="campaign"
          />
        </div>
      </template>

      <template v-else-if="isApprovingRateClass">
        <rate-class-approval
          :order="orderToApprove"
          @change="approved_rate_class = $event"
        />
      </template>

      <div v-else>
        Waiting for Admin approval. Please contact us for more information about
        your order.
      </div>
    </transition>

    <template slot="footer">
      <customer-mutation class="w-full" @data="afterApproval">
        <div
          slot-scope="{ loading, approveRateClass, approvePaymentMethod }"
          class="flex w-full"
        >
          <template v-if="!loading">
            <template v-if="isApprovingPaymentMethod">
              <el-button
                class="button-white"
                @click="
                  approvePaymentMethod(buyerId, creditCardPaymentMethod.id)
                "
              >
                <slot name="save-text">Require Credit Card</slot>
              </el-button>
              <el-button
                class="button-green"
                @click="approvePaymentMethod(buyerId, paymentMethodId)"
              >
                <slot name="save-text">Accept Pay By Invoice</slot>
              </el-button>
            </template>

            <template v-else-if="isApprovingRateClass">
              <el-button
                v-if="isNewRateClass"
                class="button-red"
                @click="showConfirmCancelDialog = true"
              >
                Reject and Cancel Order
              </el-button>
              <el-button
                v-else
                class="button-green"
                @click="approveRateClass(buyerId, approved_rate_class)"
              >
                Approve {{ RateClassLabel[approved_rate_class] }} Rate Class
              </el-button>

              <confirm-dialog
                v-if="showConfirmCancelDialog"
                :modal="false"
                title="Are you sure?"
                confirm-class="button-red"
                @close="showConfirmCancelDialog = false"
                @confirm="approveRateClass(buyerId, approved_rate_class)"
              >
                Are you sure you want to cancel this entire order? This action
                cannot be undone. You will need to inform the buyer and book a
                new order at the correct rate class, if applicable.
              </confirm-dialog>
            </template>
            <template v-else>
              <el-button @click="$emit('close')">Close</el-button>
            </template>
          </template>

          <loading-button v-else />
        </div>
      </customer-mutation>
    </template>
  </el-dialog>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import LoadingButton from '@/components/Core/Loading/LoadingButton';
import PaymentMethodApproval from '@/components/Supplier/Common/PaymentMethodApproval';
import RateClassApproval from '@/components/Supplier/Common/RateClassApproval';
import { CustomerMutation } from '@/components/Mutations';

import {
  OrderApprovalStatus,
  OrderStatus,
  PaymentMethodName,
  RateClassLabel
} from '@/constants';

import { find, map } from 'lodash';

export default {
  components: {
    ConfirmDialog,
    CustomerMutation,
    LoadingButton,
    PaymentMethodApproval,
    RateClassApproval
  },
  props: {
    approvalFor: { type: String, default: null },
    order: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      orderToApprove: this.order,
      // This is snake cased because it is an attribute name for Order
      approved_rate_class: this.order.rate_class,
      isSaving: false,
      showConfirmCancelDialog: false,
      RateClassLabel
    };
  },

  computed: {
    paymentMethodsList() {
      return this.$store.getters['auth/supplier'].paymentMethods;
    },
    buyerId() {
      return this.orderToApprove.buyer_id;
    },

    theTitle() {
      return this.isApprovingRateClass
        ? 'Rate Class Approval'
        : 'Invoice Approval';
    },

    /**
     * XXX: This is some weird bug where sometimes the order is missing the payment Method even though it
     * is returned with the Apollo query. Happens sporadically, maybe a race condition of sorts. Could
     * be a bug with the Apollo Client itself maybe??
     */
    paymentMethodId() {
      if (this.orderToApprove.paymentMethod) {
        return this.orderToApprove.paymentMethod.id;
      }

      return this.alternatePaymentMethod.id;
    },

    isApprovingPaymentMethod() {
      // Supplier is approving payment method if the order doesn't have approvedPaymentMethod set
      return !this.orderToApprove.approvedPaymentMethod;
    },

    isApprovingRateClass() {
      return (
        !this.isApprovingPaymentMethod &&
        !this.orderToApprove.approved_rate_class
      );
    },

    alternatePaymentMethod() {
      return find(this.paymentMethodsList, { name: PaymentMethodName.INVOICE });
    },

    creditCardPaymentMethod() {
      return find(this.paymentMethodsList, { name: PaymentMethodName.CREDIT });
    },

    isNewRateClass() {
      return this.approved_rate_class !== this.orderToApprove.rate_class;
    }
  },

  methods: {
    afterApproval({ mutateCustomer: { affectedOrders } }) {
      // XXX: Since el-table clones the data, the mutation will not provide an updated order object
      // if the parent is using el-table, but we know we have the correct data from the mutation response
      // so we can use the mutated order here to continue through the flow
      if (affectedOrders && affectedOrders.length > 0) {
        this.orderToApprove = find(affectedOrders, {
          id: this.orderToApprove.id
        });
      }

      // Once the order is approved (or at least approved rate class and payment method are set), we're done!
      if (this.orderToApprove.approval_status === OrderApprovalStatus.PENDING) {
        if (this.orderToApprove.status === OrderStatus.CANCELED) {
          this.$message.warning(
            'The following Orders have been canceled:\n#' +
              map(affectedOrders, 'ref').join('\n#')
          );
        } else {
          this.$message.success(
            'The following Orders have been approved:\n#' +
              map(affectedOrders, 'ref').join('\n#')
          );
        }
        this.$emit('close');
      }
    }
  }
};
</script>
