<template>
  <div
    class="rounded shadow-gray-1"
    :class="{ jagged: jaggedBorder }"
    @click="$emit('click')"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
  >
    <slot name="header" />
    <slot />
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  props: {
    jaggedBorder: Boolean
  }
};
</script>

<style lang="scss" scoped>
.jagged {
  position: relative;
  box-shadow: 0 10px 20px 0 rgba(205, 212, 227, 0.6),
    0 -10px 20px 0 rgba(205, 212, 227, 0.6);
}

.jagged:before {
  content: '';
  display: block;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  height: 10px;
  background-size: 12px 20px;
  background: linear-gradient(
      -45deg,
      transparent 33.333%,
      white 33.333%,
      white 66.667%,
      transparent 66.667%
    )
    linear-gradient(
      45deg,
      transparent 33.333%,
      white 33.333%,
      white 66.667%,
      transparent 66.667%
    ) -11px 0;
}

.jagged:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  height: 10px;
  background-size: 12px 20px;
  background: linear-gradient(
      45deg,
      transparent 33.333%,
      white 33.333%,
      white 66.667%,
      transparent 66.667%
    )
    linear-gradient(
      -45deg,
      transparent 33.333%,
      white 33.333%,
      white 66.667%,
      transparent 66.667%
    )
    0 -11px;
}
</style>
