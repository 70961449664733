<template>
  <iframe-v4-layout id="v4-supplier-ads-approvals-table" :url="url" />
</template>

<script>
import IframeV4Layout from '@/components/Core/IframeV4Layout';
import { getAuthorizationToken } from '@/utils/authorization';

export default {
  components: { IframeV4Layout },
  computed: {
    url() {
      const jwt = getAuthorizationToken();
      // Get filter from URL and convert to URI component to pass to iframe URL
      const filter = this.$route.query.filter;
      return (
        this.$apiUrl +
        '/supplier-v4/ads/approvals?jwt_token=' +
        jwt +
        (filter ? '&filter=' + encodeURIComponent(JSON.stringify(filter)) : '')
      );
    }
  }
};
</script>
