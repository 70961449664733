<template>
  <confirm-dialog
    title="Change Run Date"
    width="30em"
    :is-saving="loading"
    @confirm="confirmChange"
    @close="$emit('close')"
  >
    <div>
      <reschedule-ad-picker
        :ad="ad"
        :supplier-id="supplierId"
        :dates="selectedDates"
        @input="changeDates"
      />

      <template v-if="ad">
        <template v-if="isDateRange">
          <div class="mt-5">
            <div class="font-bold">Original Date Range</div>
            <div class="mt-2">
              {{ ad.start_date | date }} to {{ ad.end_date | date }}
            </div>
          </div>
          <div class="mt-3">
            <div class="font-bold">New Date Range</div>
            <div class="mt-2">
              <template v-if="startDate && endDate"
                >{{ startDate | date }} to {{ endDate | date }}
              </template>
              <template v-else><i>Select Date Range</i></template>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="mt-5 flex">
            <div class="w-1/2 flex-grow">
              <div class="font-bold">Original Date</div>
              <div class="mt-2">
                {{ ad.start_date | date }}
              </div>
            </div>
            <div class="flex-grow w-1/2">
              <div class="font-bold">New Date</div>
              <div class="mt-2">
                <template v-if="startDate">{{ startDate | date }}</template>
                <template v-else><i>Select Date</i></template>
              </div>
            </div>
          </div>
        </template>
      </template>

      <el-alert
        v-else-if="draftInvoiceCount"
        type="error"
        title=""
        class="mt-5"
        :closable="false"
      >
        {{ draftInvoicesWarning }}
      </el-alert>
    </div>
  </confirm-dialog>
</template>

<script>
import moment from 'moment';
import { datetimeDb } from '@/utils/filters';
import { ScheduleTypeValue } from '@/constants';

import ConfirmDialog from '@/components/Core/ConfirmDialog';
import RescheduleAdPicker from '@/components/Supplier/Ads/RescheduleAdPicker';

export default {
  components: {
    RescheduleAdPicker,
    ConfirmDialog
  },
  props: {
    ad: {
      type: Object,
      default: null
    },
    supplierId: {
      type: String,
      default: null
    },
    loading: Boolean,
    allowAnyDate: Boolean
  },
  data() {
    return {
      startDate: null,
      endDate: null
    };
  },

  computed: {
    selectedDates() {
      if (this.startDate) {
        return [
          {
            start_date: this.startDate,
            end_date: this.endDate
          }
        ];
      } else {
        return [];
      }
    },

    isDateRange() {
      if (this.ad) {
        return (
          this.ad.orderProduct.scheduleType.value ===
          ScheduleTypeValue.CONTIGUOUS
        );
      } else {
        return false;
      }
    },

    activeInvoiceCount() {
      return this.ad && this.ad.orderLineItem.invoiced_active_count;
    },

    draftInvoiceCount() {
      return this.ad && this.ad.orderLineItem.invoiced_draft_count;
    },

    draftInvoicesWarning() {
      return `You are modifying an ad that appears on ${
        this.draftInvoiceCount === 1
          ? 'a draft invoice'
          : `${this.draftInvoiceCount} draft invoices`
      }. Changing the run date will potentially change the amount due on those invoices.`;
    }
  },

  methods: {
    changeDates(selectedDates) {
      let date = selectedDates[0];

      this.startDate = date.start_date;
      this.endDate = date.end_date;
    },

    confirmChange() {
      // eslint-disable-next-line
      let start_date = datetimeDb(moment(this.startDate).startOf('day'));

      // eslint-disable-next-line
      let end_date = datetimeDb(
        moment(this.endDate || this.startDate).endOf('day')
      );

      this.$emit('change', {
        start_date,
        end_date
      });
    }
  }
};
</script>
