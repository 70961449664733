import { render, staticRenderFns } from "./PaymentMethodApproval.vue?vue&type=template&id=56e46925&scoped=true&"
import script from "./PaymentMethodApproval.vue?vue&type=script&lang=js&"
export * from "./PaymentMethodApproval.vue?vue&type=script&lang=js&"
import style0 from "./PaymentMethodApproval.vue?vue&type=style&index=0&id=56e46925&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56e46925",
  null
  
)

export default component.exports