<template>
  <supplier-orders-mutation @done="$emit('close')">
    <template v-slot="{ loading, approveOrder, requestChanges, rejectOrder }">
      <el-dialog
        :visible="true"
        :close-on-click-modal="false"
        :show-close="false"
        title="Review Order"
        width="70%"
        append-to-body
        @update:visible="$event || $emit('close')"
        @close="$emit('close')"
      >
        <div v-if="isRejecting">
          <el-form ref="noteForm" :rules="rules" :model="noteForm">
            <custom-el-form-item
              prop="note"
              label="Please enter a note about the changes you would like to request. "
            >
              <el-input
                v-model="noteForm.note"
                class="mt-4"
                placeholder="Enter Note Here"
                type="textarea"
                :rows="4"
                @keyup.enter.native.stop.prevent
              />
            </custom-el-form-item>
          </el-form>
        </div>
        <div v-else class="flex items-center flex-col text-center px-8">
          <h2 class="text-dark-gray">
            Flytedesk has booked an order at your publication!
          </h2>
          <div class="my-4">
            This order is pending your approval. Please review the IO and verify
            that the
            <b class="text-dark-gray">
              scheduled dates and products are available,
            </b>
            and the price is accurate.
          </div>
          <div>
            <i>
              If there is a pricing error, you do not need to reject the IO.
              Please reach out to your flytedesk rep.
            </i>
          </div>
          <div>
            <i>
              Please only request cancellation (reject) this IO if the entire
              order can’t run. If there are certain products/dates/rates that
              need to be adjusted, please request changes.
            </i>
          </div>
          <div class="mt-8 w-48">
            <io-download-link
              :order="order"
              :text="'Review IO #' + order.ref"
              button-class="button-white-blue"
              button
            />
          </div>
        </div>
        <template slot="footer">
          <template v-if="!loading">
            <template v-if="isRejecting">
              <el-button class="button-white" @click="isRejecting = false">
                Cancel
              </el-button>
              <el-button class="button-red" @click="onReject(rejectOrder)">
                Request Cancellation (Reject)
              </el-button>
              <el-button class="button-blue" @click="onReject(requestChanges)">
                Request Changes
              </el-button>
            </template>
            <template v-else>
              <el-button class="button-white" @click="$emit('close')">
                Review Later
              </el-button>
              <el-button class="button-white" @click="isRejecting = true">
                Request Change or Cancellation
              </el-button>
              <el-button class="button-green" @click="approveOrder(order.id)">
                Approve Order
              </el-button>
            </template>
          </template>

          <loading-button v-else />
        </template>
      </el-dialog>
    </template>
  </supplier-orders-mutation>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import IoDownloadLink from '@/components/Order/IoDownloadLink';
import { filePdf as pdfIcon } from '@/vendor/icons';
import { SupplierOrdersMutation } from '@/components/Mutations';

export default {
  components: {
    IoDownloadLink,
    CustomElFormItem,
    SupplierOrdersMutation
  },
  props: {
    order: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isRejecting: false,
      rules: {
        note: [
          { required: true, message: 'Please enter a note', trigger: 'blur' }
        ]
      },
      noteForm: {
        note: ''
      },

      // Icons
      pdfIcon
    };
  },
  watch: {
    visible(newValue) {
      if (!newValue) {
        this.isRejecting = false;
        this.noteForm.note = '';
      }
    }
  },
  methods: {
    onReject(rejectMethod) {
      this.$refs.noteForm.validate(valid => {
        if (valid) {
          rejectMethod(this.order.id, { text: this.noteForm.note });
        }
      });
    }
  }
};
</script>
