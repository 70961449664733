<template>
  <div
    class="flex flex-col items-center w-88 h-fit text-center border shadow-box rounded"
  >
    <div class="w-28 -mt-10">
      <div
        :style="imageStyle"
        class="bg-gray bg-center bg-contain bg-no-repeat h-0 pb-full rounded-full"
      >
        <icon
          v-if="!imageSource"
          :icon="userIcon"
          class="text-6.5xl text-gunmetal"
        />
      </div>
    </div>
    <h2 class="py-2">
      My Flytedesk Rep
    </h2>
    <div class="text-md w-full">
      <div class="font-semibold">
        {{ contact.name }}
      </div>
      {{ contact.title }}
      <div class="flex justify-between p-4">
        <div v-if="contact.phone" class="w-1/2">
          <icon class="text-blue text-lg" :icon="phone" aria-hidden="true" />
          <br />
          {{ contact.phone | phone }}
        </div>
        <div
          v-if="contact.email"
          ref="emailContainer"
          class="w-1/2 truncate"
          :class="{ 'w-full': !contact.phone }"
        >
          <icon class="text-blue text-xl" :icon="mail" aria-hidden="true" />
          <br />
          <el-tooltip
            v-if="isEmailTruncated"
            effect="light"
            placement="bottom"
            :content="contact.email"
          >
            <div>
              {{ contact.email }}
            </div>
          </el-tooltip>
          <div v-else>
            {{ contact.email }}
          </div>
        </div>
      </div>
      <address class="border-top text-dark-silver py-4">
        Flytedesk
        <br />
        <div
          v-html="systemSettings.contact.full_address.replace(/\n/g, '<br />')"
        />
      </address>
    </div>
  </div>
</template>

<script>
import { mail, phone, userCircleO as userIcon } from '@/vendor/icons';

export default {
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isEmailTruncated: false,
      mail,
      phone,
      userIcon
    };
  },
  computed: {
    systemSettings() {
      return this.$store.getters['auth/systemSettings'];
    },
    imageSource() {
      return this.contact.profilePicture &&
        this.contact.profilePicture.url &&
        this.contact.profilePicture.filename
        ? this.contact.profilePicture.url
        : null;
    },
    imageStyle() {
      if (this.imageSource) {
        return {
          'background-image': this.imageSource
            ? `url("${this.imageSource}")`
            : undefined
        };
      }

      return {};
    }
  },
  mounted() {
    this.isEmailTruncated =
      this.$refs &&
      this.$refs.emailContainer &&
      this.$refs.emailContainer.offsetWidth <
        this.$refs.emailContainer.scrollWidth;
  }
};
</script>

<style lang="scss" scoped>
.user-profile-icon {
  font-size: 20em;
}
</style>
