<template>
  <div class="ad-history-change">
    <h4>
      {{ change.label }}
      <template v-if="$can('manage_any_custom_form')">
        ({{ change.name }})
      </template>
    </h4>
    <div class="mt-2 flex flex-wrap">
      <div v-if="change.oldValue" class="old-value">
        <form-field-response-preview
          can-download
          spread-on-hover
          enable-preview
          :value="change.oldValue"
          :type="change.type"
          :icons="AdStatusInfo"
        />
      </div>
      <div class="new-value">
        <form-field-response-preview
          can-download
          spread-on-hover
          enable-preview
          :value="change.newValue"
          :type="change.type"
          :icons="AdStatusInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { AdStatusInfo } from '@/constants';
import FormFieldResponsePreview from '@/components/Core/Form/FormBuilder/FormFieldResponsePreview';

export default {
  components: {
    FormFieldResponsePreview
  },
  props: {
    change: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      AdStatusInfo
    };
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.old-value {
  margin-right: 1em;
  padding: 0.3em 1em;
  background: $color-light-red;
  text-decoration: line-through;
  border-radius: 5px;
}

.new-value {
  padding: 0.3em 1em;
  background: $color-light-green;
  border-radius: 5px;
}
</style>
