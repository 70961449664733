<script>
import { getSupplierOrdersSearchTools } from '@/graphql/supplier/queries.graphql';

import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],

  query() {
    return {
      query: getSupplierOrdersSearchTools,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
