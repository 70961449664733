<template>
  <div class="supplier-ads">
    <supplier-ads-query
      :pager="pager"
      :loading.sync="isLoadingAds"
      @data="onReceivedAds"
    />

    <supplier-ads-search-tools-query
      @data="searchTools = $event.supplier.adsData.searchTools"
    />

    <div class="flex flex-row border-top">
      <div class="flex-shrink-0">
        <collapsible-search-group-sidebar
          v-model="searchInput"
          :search-tools="searchTools || []"
          :is-loading="!searchTools"
          :filter="pager.filter"
          @search="pager = { ...pager, ...$event }"
        />
      </div>
      <div
        class="flex flex-1 flex-col p-8 overflow-x-auto min-w-40 border-left"
      >
        <ads-mutation>
          <template v-slot="{ isSaving, exportSupplierAds }">
            <filter-toolbar
              class="items-end"
              placeholder="Search Ads, Customers, etc."
              :is-exporting="isSaving"
              :value="pager.filter"
              @input="pager = { ...pager, filter: $event }"
              @export="onExport(exportSupplierAds)"
            />
          </template>
        </ads-mutation>

        <ads-mutation @done="onDoneMutating">
          <template
            v-slot="{
              isSaving: isSavingAds,
              cancelAd,
              deleteAd,
              rescheduleAd,
              resumeCanceledAd
            }"
          >
            <div class="relative">
              <el-table
                :data="(!isLoadingAds && ads) || []"
                border
                class="w-full mt-6 el-table-slim"
              >
                <table-empty-slot
                  slot="empty"
                  :is-loading="isLoadingAds"
                  :has-data="!!ads"
                  :empty-message="emptyFilterMessage"
                  error-message="There was a problem loading your ads. Please try again later."
                />

                <el-table-column label="Status" align="center" width="100">
                  <status-icon
                    slot-scope="{ row }"
                    :icons="AdStatusInfo"
                    :status="row.status"
                  />
                </el-table-column>

                <el-table-column
                  label="Run Date(s)"
                  align="center"
                  width="110"
                  prop="start_date"
                >
                  <div slot-scope="{ row }">
                    <ad-date-column :ad="row" />
                  </div>
                </el-table-column>

                <el-table-column label="IO" align="center" width="100">
                  <supplier-io-column
                    slot-scope="{ row }"
                    :campaign="row.campaign"
                    :order="row.order"
                    @review="onReviewOrder(row)"
                  />
                </el-table-column>

                <el-table-column label="Rep">
                  <template v-slot="{ row }">
                    <rep-column :order="row.order" />
                  </template>
                </el-table-column>

                <el-table-column label="Customer">
                  <customer-column
                    slot-scope="{ row }"
                    :customer="row.order.customer"
                    :campaign="row.campaign"
                  />
                </el-table-column>

                <el-table-column label="Ad Details">
                  <ad-column slot-scope="{ row }" :ad="row" />
                </el-table-column>

                <el-table-column label="Fulfillment" width="200">
                  <supplier-fulfillment-column slot-scope="{ row }" :ad="row" />
                </el-table-column>

                <el-table-column label="Creative" align="center" width="220">
                  <template slot-scope="{ row }">
                    <creative-column
                      :is-disabled="!$can('pub_manage_creative')"
                      :ad="row"
                      :start-open="
                        showCreativeDialog && row.id === $route.params.ad.id
                      "
                      @close="showCreativeDialog = false"
                    />
                  </template>
                </el-table-column>

                <el-table-column label="Notes" min-width="35px" align="center">
                  <template v-slot="{ row }">
                    <supplier-notes-column
                      :ads="[row]"
                      :order="row.order"
                      :start-open="
                        showNoteDialog && row.id === $route.params.entity_id
                      "
                      @close="showNoteDialog = false"
                    />
                  </template>
                </el-table-column>

                <el-table-column label="Action" width="100" align="center">
                  <template v-slot="{ row }">
                    <action-menu
                      :actions="actions(row)"
                      @reschedule="onReschedule(row)"
                      @resume="onResume(resumeCanceledAd, row)"
                      @cancel="onCancel(row)"
                      @review="onReviewOrder(row)"
                      @delete="onDeleteAd(row)"
                      @serving-tags="onShowServingTags(row)"
                      @ad-history="showHistoryForAd = row"
                    >
                      <io-download-link :order="row.order" menu-item />
                    </action-menu>
                  </template>
                </el-table-column>
              </el-table>

              <pagination
                v-if="paginatorInfo"
                :info="paginatorInfo"
                :pager.sync="pager"
                class="mt-5"
              />
            </div>
            <!-- Dialogs -->
            <ad-history-dialog
              v-if="showHistoryForAd"
              :ad="showHistoryForAd"
              @close="showHistoryForAd = null"
            />
            <national-order-review-dialog
              v-if="
                showNationalOrderReviewDialog &&
                  $can('pub_accept_or_reject_order')
              "
              :order="selectedAd.order"
              @close="showNationalOrderReviewDialog = false"
            />
            <local-order-review-dialog
              v-if="
                $can('pub_change_invoice_settings') &&
                  showLocalOrderReviewDialog
              "
              :order="selectedAd.order"
              :campaign="selectedAd.campaign"
              @close="showLocalOrderReviewDialog = false"
            />
            <reschedule-dialog
              v-if="rescheduleDialogVisible"
              :ad="selectedAd"
              :loading="isSavingAds"
              :allow-any-date="$can('pub_unrestricted_reschedule_ads')"
              @close="rescheduleDialogVisible = false"
              @change="rescheduleAd(selectedAd, $event)"
            />

            <ad-status-change-dialog
              v-if="statusChangeDialogVisible"
              :is-loading="isSavingAds"
              @close="statusChangeDialogVisible = false"
              @save="resumeCanceledAd(selectedAd)"
              @override="resumeCanceledAd(selectedAd, $event)"
            />

            <cancel-ad-dialog
              v-if="cancelDialogVisible"
              :ad="selectedAd"
              :is-loading="isSavingAds"
              @close="cancelDialogVisible = false"
              @cancel-ad="cancelAd(selectedAd, false)"
              @cancel-both="cancelAd(selectedAd, true)"
            />

            <confirm-dialog
              v-if="showDeleteDialog"
              :title="'Permanently Delete ' + selectedAd.ref"
              :is-saving="isSavingAds"
              confirm-class="button-red"
              confirm-text="Delete Ad"
              @close="showDeleteDialog = false"
              @confirm="onConfirmDelete(deleteAd)"
            >
              Are you sure you want to permanently delete ad #{{
                selectedAd.ref
              }}? This will also delete any associated billing line items and
              creative.
            </confirm-dialog>

            <copy-ad-serving-code-dialog
              v-if="showAdServingCodeDialog"
              :ad="selectedAd"
              @close="showAdServingCodeDialog = false"
            />
          </template>
        </ads-mutation>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { filterDateBetween } from '@/utils/sortFilter';
import { ellipsisV as ellipsisIcon, filePdf } from '@/vendor/icons';
import {
  AccountType,
  AdStatus,
  AdStatusInfo,
  CampaignType,
  OrderApprovalStatus
} from '@/constants';
import { date as formatDate } from '@/utils/filters';
import { download } from '@/vendor/download';

import ActionMenu from '@/components/Core/ActionMenu';
import AdStatusChangeDialog from '@/components/Core/AdStatusChangeDialog';
import CancelAdDialog from '@/components/Core/CancelAdDialog';
import CollapsibleSearchGroupSidebar from '@/components/Core/CollapsibleSearchGroupSidebar';
import FilterToolbar from '@/components/Core/Table/FilterToolbar';
import IoDownloadLink from '@/components/Order/IoDownloadLink';
import LocalOrderReviewDialog from '@/components/Supplier/Ads/LocalOrderReviewDialog';
import NationalOrderReviewDialog from '@/components/Supplier/Ads/NationalOrderReviewDialog';
import Pagination from '@/components/Core/Table/Pagination';
import SupplierAdsSearchToolsQuery from '@/components/Queries/Supplier/Ads/SupplierAdsSearchToolsQuery';
import SupplierNotesColumn from '@/components/Supplier/Ads/SupplierNotesColumn';
import RescheduleDialog from '@/components/Supplier/Ads/RescheduleDialog';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import { AdsMutation } from '@/components/Mutations';
import { SupplierAdsQuery } from '@/components/Queries';

import {
  AdColumn,
  AdDateColumn,
  CreativeColumn,
  CustomerColumn,
  SupplierFulfillmentColumn,
  SupplierIoColumn
} from '@/components/Core/Table/Columns';
import RepColumn from '@/components/Core/Table/Columns/RepColumn';
import CopyAdServingCodeDialog from '@/components/Core/CopyAdServingCodeDialog';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import AdHistoryDialog from '@/components/Supplier/Ads/AdHistoryDialog';

export default {
  components: {
    AdHistoryDialog,
    ConfirmDialog,
    CopyAdServingCodeDialog,
    RepColumn,
    ActionMenu,
    AdColumn,
    AdDateColumn,
    AdsMutation,
    AdStatusChangeDialog,
    CancelAdDialog,
    CollapsibleSearchGroupSidebar,
    CreativeColumn,
    CustomerColumn,
    FilterToolbar,
    IoDownloadLink,
    LocalOrderReviewDialog,
    NationalOrderReviewDialog,
    Pagination,
    SupplierAdsQuery,
    SupplierAdsSearchToolsQuery,
    SupplierFulfillmentColumn,
    SupplierIoColumn,
    SupplierNotesColumn,
    RescheduleDialog,
    StatusIcon,
    TableEmptySlot
  },

  data() {
    return {
      searchInput: {},

      isLoadingAds: false,
      searchTools: null,
      paginatorInfo: null,
      ads: null,

      adToReschedule: false,
      AccountType,
      AdStatus,
      AdStatusInfo,

      // Dialog visibility booleans
      cancelDialogVisible: false,
      showDeleteDialog: false,
      showLocalOrderReviewDialog: false,
      showNationalOrderReviewDialog: false,
      rescheduleDialogVisible: false,
      statusChangeDialogVisible: false,
      showAdServingCodeDialog: false,
      showHistoryForAd: null,

      selectedAd: null,
      showCreativeDialog: false,
      showNoteDialog: false,

      // Icons
      filePdf,
      ellipsisIcon
    };
  },

  computed: {
    dateRangeFilter() {
      return this.pager.filter.filterDateRange;
    },
    pager: {
      get() {
        return this.$store.getters['ads/pager'];
      },
      set(value) {
        this.$store.commit('ads/setPager', value);
      }
    },
    emptyFilterMessage() {
      if (this.dateRangeFilter) {
        let startDate = this.dateRangeFilter['>='];
        let endDate = this.dateRangeFilter['<='];

        let str =
          "You don't have any ads running between " + formatDate(startDate);

        if (endDate) {
          str += ' and ' + formatDate(endDate);
        } else {
          str += ' and the end of time';
        }

        return str;
      } else {
        return "You don't have any ads :(";
      }
    }
  },

  created() {
    this.pager = {
      ...this.pager,
      filter: this.getDefaultFilter(),
      sort: [{ column: 'start_date', order: 'descending' }]
    };

    let filters;

    filters = {
      'Run Date Calendar': {
        value: this.pager.filter.filterDateRange,
        searchValue: {
          filterDateRange: this.pager.filter.filterDateRange
        }
      }
    };

    if (this.pager.filter.status) {
      filters['Ad Status'] = {
        searchValue: {
          status: this.pager.filter.status
        },
        value: Array.isArray(this.pager.filter.status)
          ? this.pager.filter.status
          : [this.pager.filter.status]
      };
    }

    this.searchInput = {
      filters
    };
  },

  methods: {
    getDefaultFilter() {
      if (
        this.$route.query &&
        this.$route.query.filter &&
        typeof this.$route.query.filter !== 'string'
      ) {
        return { ...this.$route.query.filter };
      } else {
        return {
          filterDateRange: filterDateBetween(moment(), moment().add(14, 'days'))
        };
      }
    },
    actions(ad) {
      let actionsArray = [];
      const isLocal = ad.campaign && ad.campaign.type === CampaignType.LOCAL;

      // conditionals on actions
      const canReschedule =
        ad.status !== AdStatus.CANCELED &&
        (isLocal
          ? this.$can('pub_reschedule_ads')
          : this.$can('manage_national_ads'));
      const canCancel =
        ad.status !== AdStatus.CANCELED &&
        ad.orderLineItem.invoiced_count === 0 &&
        (isLocal
          ? this.$can('pub_cancel_ads')
          : this.$can('manage_national_ads'));
      const canDelete =
        ad.status === AdStatus.CANCELED &&
        (isLocal
          ? this.$can('pub_delete_ads')
          : this.$can('manage_national_ads'));
      const canResume =
        ad.status === AdStatus.CANCELED &&
        (isLocal
          ? this.$can('pub_cancel_ads')
          : this.$can('manage_national_ads'));
      const canReview =
        ad.order.approval_status === OrderApprovalStatus.PENDING &&
        this.$can('pub_accept_or_reject_order');

      // push allowed actions to array for rendering
      if (canReschedule) {
        actionsArray.push({ label: 'Reschedule', value: 'reschedule' });
      }
      if (canResume) {
        actionsArray.push({ label: 'Undo Cancel', value: 'resume' });
      }
      if (canCancel) {
        actionsArray.push({ label: 'Cancel', value: 'cancel' });
      }
      if (canReview) {
        actionsArray.push({ label: 'Review', value: 'review' });
      }

      if (ad.servingTags) {
        actionsArray.push({
          label: 'Copy Ad Serving Code',
          value: 'serving-tags'
        });
      }

      actionsArray.push({
        value: 'ad-history',
        label: 'Ad History'
      });

      if (canDelete) {
        actionsArray.push({
          label: 'Delete',
          value: 'delete',
          class: 'text-red'
        });
      }

      return actionsArray;
    },
    onReceivedAds({ supplier: { adsData } }) {
      this.ads = adsData.ads.data;
      this.paginatorInfo = adsData.ads.paginatorInfo;

      // Show the Dialogs if they are requested after the query has executed
      const params = this.$route.params;

      if (params) {
        if (params.showCreativeDialog) this.showCreativeDialog = true;
        if (params.showNoteDialog) this.showNoteDialog = true;
      }
    },
    async onExport(exportSupplierAds) {
      const data = await exportSupplierAds(this.pager.filter);

      download(data.mutateAd.exportSupplierAds, 'Ads.csv');
    },
    onCancel(ad) {
      this.selectedAd = ad;
      this.cancelDialogVisible = true;
    },
    onDeleteAd(ad) {
      this.selectedAd = ad;
      this.showDeleteDialog = true;
    },
    async onConfirmDelete(deleteAd) {
      await deleteAd(this.selectedAd.id);
      this.showDeleteDialog = false;
    },
    onDoneMutating() {
      this.cancelDialogVisible = false;
      this.showLocalOrderReviewDialog = false;
      this.showNationalOrderReviewDialog = false;
      this.rescheduleDialogVisible = false;
      this.statusChangeDialogVisible = false;
    },
    onReschedule(ad) {
      this.selectedAd = ad;
      this.rescheduleDialogVisible = true;
    },
    onResume(resumeCanceledAd, ad) {
      if (this.$can('do_anything')) {
        this.selectedAd = ad;
        this.statusChangeDialogVisible = true;
      } else {
        resumeCanceledAd(ad);
      }
    },
    onReviewOrder(ad) {
      this.selectedAd = ad;

      if (ad.campaign.type === CampaignType.NATIONAL) {
        this.showNationalOrderReviewDialog = true;
      } else {
        this.showLocalOrderReviewDialog = true;
      }
    },
    onShowServingTags(ad) {
      this.selectedAd = ad;
      this.showAdServingCodeDialog = true;
    }
  }
};
</script>
