<template>
  <supplier-orders-mutation @done="$emit('close')">
    <template v-slot="{ isSaving, cancelAllLineItems }">
      <confirm-dialog
        :title="'Cancel Order ' + order.ref"
        :is-saving="isSaving"
        confirm-text="Cancel"
        confirm-class="button-red"
        :cancel-text="hasCancelableAds ? 'Nevermind' : 'Got it!'"
        :disabled="!hasCancelableAds"
        @confirm="onCancel(cancelAllLineItems)"
        @close="$emit('close')"
      >
        <div>
          <supplier-ads-query
            :pager="pager"
            :options="{
              fetchPolicy: 'network-only'
            }"
            @data="ads = $event.supplier.adsData.ads.data"
          />

          <template v-if="!ads">
            <loader-box :height="20" />
          </template>
          <template v-else>
            <template v-if="hasCancelableAds">
              <div>
                Are you sure you want to cancel all the ads in the order
                {{ order.ref }}?
              </div>
              <div class="mt-3">
                <div v-if="canceledAds.length" class="mb-2">
                  <template v-if="canceledAds.length === 1">
                    There is 1 canceled ad
                  </template>
                  <template v-else>
                    There are {{ canceledAds.length }} canceled ads
                  </template>
                  in this order.
                </div>
                <div>
                  <template v-if="invoicedAds.length === 0">
                    This will cancel {{ cancelableAds.length }} ads and their
                    associated billing line items, and the order status will
                    change to canceled.
                  </template>
                  <template v-else>
                    This will cancel {{ cancelableAds.length }} /
                    {{ ads.length }} ads and their associated billing line
                    items. The order will <b>not</b> be canceled because there
                    will still be ads running.
                  </template>
                </div>
              </div>
            </template>

            <!-- If there are no Ads left to cancel -->
            <template v-else>
              <div v-if="canceledAds.length > 0" class="font-bold text-red">
                All the ads in this order have already been canceled
              </div>
              <div v-else class="font-bold text-red">
                There are no ads eligible to be canceled in this order
              </div>
            </template>

            <template v-if="invoicedAds.length > 0">
              <div class="mt-3">
                <div>
                  The following ads have already been added to an invoice and
                  therefore will not be canceled
                </div>
                <div class="mt-1 ml-3 max-h-10 overflow-scroll-y">
                  <div
                    v-for="ad in invoicedAds"
                    :key="ad.id"
                    class="flex items-center "
                  >
                    <div class="font-bold">{{ ad.ref }}</div>
                    <div class="ml-1 text-dark-silver">{{ ad.sku }}</div>
                    <status-icon
                      class="ml-1"
                      :icons="AdStatusInfo"
                      :status="ad.status"
                      inline
                    />
                    <div class="ml-1">{{ ad.start_date | date }}</div>
                    <template v-if="showEndDate(ad)">
                      to <span>{{ ad.end_date | date }}</span>
                    </template>
                  </div>
                </div>
              </div>
              <div v-if="hasCancelableAds" class="mt-3 text-dark-silver">
                Note: This action may cancel ads that already ran or are
                currently running as well as future ads. It will not cancel ads
                already invoiced. To cancel invoiced ads, you must first void
                their associated invoice.
              </div>
            </template>
          </template>
        </div>
      </confirm-dialog>
    </template>
  </supplier-orders-mutation>
</template>

<script>
import moment from 'moment';
import { AdStatus, AdStatusInfo } from '@/constants';
import { SupplierOrdersMutation } from '@/components/Mutations';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import SupplierAdsQuery from '@/components/Queries/Supplier/Ads/SupplierAdsQuery';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';

export default {
  components: {
    StatusIcon,
    SupplierAdsQuery,
    ConfirmDialog,
    SupplierOrdersMutation
  },
  props: {
    order: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // Consts
      AdStatusInfo,

      // Data
      ads: null
    };
  },
  computed: {
    canceledAds() {
      return this.ads.filter(a => a.status === AdStatus.CANCELED);
    },
    cancelableAds() {
      return this.ads.filter(
        a =>
          a.status !== AdStatus.CANCELED && a.orderLineItem.invoiced_count === 0
      );
    },
    hasCancelableAds() {
      return this.ads && this.cancelableAds.length > 0;
    },
    invoicedAds() {
      return this.ads.filter(a => a.orderLineItem.invoiced_count > 0);
    },
    pager() {
      return {
        filter: {
          'order.id': this.order.id
        },
        perPage: 99999
      };
    }
  },
  methods: {
    showEndDate(ad) {
      return moment(ad.start_date).diff(ad.end_date, 'days') > 0;
    },
    async onCancel(cancelAllLineItems) {
      await cancelAllLineItems(this.order);

      this.$message.success(
        `${this.cancelableAds.length} ads were successfully canceled in order ${this.order.ref}!`
      );

      this.$emit('close');
    }
  }
};
</script>
