<template>
  <el-container id="the-supplier-layout">
    <the-default-layout>
      <the-account-bar
        slot="header"
        class="bg-blue"
        :link-to="{
          name: 'supplier.dashboard'
        }"
      >
        <span slot="title">publisher</span>

        <div class="flex items-center">
          <notifications-panel-icon />

          <account-info-button button-class="button-white-blue" />
        </div>
      </the-account-bar>
      <the-nav-bar slot="sidebar" :nav="navMenu" />
      <ad-shop-terms-notification slot="content-top" />
    </the-default-layout>
  </el-container>
</template>

<script>
import AccountInfoButton from '@/components/Auth/AccountInfoButton';
import AdShopTermsNotification from '@/components/Core/AdShopTermsNotification';
import NavToggle from '@/components/Core/NavToggle';
import TheAccountBar from '@/components/Core/Layout/TheAccountBar';
import TheDefaultLayout from '@/components/Core/Layout/TheDefaultLayout';
import TheNavBar from '@/components/Core/Layout/TheNavBar';

export default {
  components: {
    AccountInfoButton,
    AdShopTermsNotification,
    TheAccountBar,
    TheDefaultLayout,
    TheNavBar
  },

  mixins: [NavToggle],

  data() {
    return {
      showNav: 'supplier'
    };
  }
};
</script>
