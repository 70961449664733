<template>
  <div class="rate-class-approval">
    <div class="description">
      This buyer has an order pending approval, in which they’ve selected an
      unapproved rate class. If you’ve entered discounted rates in your media
      kit, this determines which rates your buyer will be pre-approved to book
      orders at. Approve this buyer to book at their selected rate class, or
      designate a new one.
    </div>

    <div class="margin-top-xxl font-bold">
      Approving this rate will also approve the pending order.
    </div>

    <div class="rate-select-box margin-top">
      <h4>Select the Buyer's Rate Class:</h4>

      <el-select v-model="rateClass" class="margin-top" @change="change">
        <el-option
          v-for="rc in RateClasses"
          :key="`rate-class-${rc.id}`"
          :value="rc.id"
          :label="rc.label"
        >
          {{ rc.label }}
        </el-option>
      </el-select>

      <div
        class="text-red margin-top has-transition"
        :class="isChanged ? '' : 'is-invisible'"
      >
        Rejecting this request will cancel the order
      </div>
    </div>
  </div>
</template>

<script>
import { RateClasses } from '@/constants';

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      RateClasses,
      rateClass: this.order.rate_class
    };
  },

  computed: {
    isChanged() {
      return this.rateClass !== this.order.rate_class;
    }
  },

  methods: {
    change() {
      this.$emit('change', this.rateClass);
    }
  }
};
</script>
