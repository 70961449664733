export const EmailServers = {
  MAILCHIMP: {
    label: 'Mailchimp',
    value: 'MAILCHIMP',
    cacheBuster: '*|UNIQID|*'
  },
  CONSTANT_CONTACT: {
    label: 'Constant Contact',
    value: 'CONSTANT_CONTACT',
    cacheBuster: '{{rs_urid}}'
  },
  TOWN_NEWS: {
    label: 'Town News / Blox',
    value: 'TOWN_NEWS',
    cacheBuster: '{{rs_urid}}'
  },
  OTHER: {
    label: 'Other',
    value: 'OTHER',
    cacheBuster: false
  }
};

export const TagTypes = {
  THIRD_PARTY: {
    value: 'THIRD_PARTY',
    label: 'Ad Server Tag'
  },
  STATIC: {
    value: 'STATIC',
    label: 'Email (Image) Tag'
  },
  PLATFORM: {
    value: 'PLATFORM',
    label: 'Flytedesk Website Tag'
  }
};
