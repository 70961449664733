<template>
  <section>
    <h3 class="uppercase text-md mb-6 font-semibold">Flytedesk National Ads</h3>

    <div v-if="isLoading || !nationalAds" class="flex">
      <loader-box
        v-for="index in 4"
        :key="index"
        height="16em"
        class="w-1/4 pr-4"
      />
    </div>
    <hooper v-else :items="ads" :settings="carouselSettings">
      <template #empty>
        <div>
          You don't have any upcoming flytedesk national ads.
          <a
            href="https://intercom.help/flytedesk/en/articles/2629197-how-can-i-get-more-national-ads"
            target="_blank"
          >
            <span>Learn how you can get more.</span>
          </a>
        </div>
      </template>
      <template v-slot="{ item }">
        <div class="pr-4">
          <card class="p-6">
            <section class="text-dark-silver">
              <div class="flex justify-between text-md mb-6">
                <div>{{ item.end_date | date }}</div>
                <div>#{{ item.ref }}</div>
                <div>{{ item.orderLineItem.pay_supplier | currency }}</div>
              </div>
              <h2 class="text-dark-gray">{{ item.buyer.buyer_company }}</h2>
              <div class="text-md text-dark-silver mt-2">
                {{ item.orderProduct.product.name }},
                {{ item.orderProduct.product_variant.name }}
              </div>
              <div class="my-4">
                <action-status
                  action-text="Approve Ad"
                  complete-text="Ad Approved"
                  :is-complete="item.approval_status === 'Approved'"
                  class="my-4"
                  @click="onApproveOrderClick(item)"
                />
                <action-status
                  action-text="Approve Creative"
                  disabled-text="Approve Creative"
                  complete-text="Creative Approved"
                  class="my-4"
                  :is-complete="isCreativeApproved(item)"
                  :is-disabled="
                    item.order.status !== OrderStatus.APPROVED ||
                      item.status !== AdStatus.PENDING_CREATIVE_APPROVAL
                  "
                  @click="onApproveCreativeClick(item)"
                />
                <action-status
                  action-text="Upload Verificiation"
                  disabled-text="Upload Verification"
                  complete-text="Verfication Approved"
                  class="my-4"
                  :is-disabled="
                    beforeRunDate(item.start_date) ||
                      (item.status !== AdStatus.READY &&
                        item.status !== AdStatus.UNVERIFIED)
                  "
                  :is-complete="
                    item.status === AdStatus.VERIFIED ||
                      item.status === AdStatus.PENDING_VERIFICATION_APPROVAL
                  "
                  @click="onUploadVerficationClick(item)"
                />
              </div>
            </section>
          </card>
        </div>
      </template>
    </hooper>
  </section>
</template>

<script>
import Card from '@/components/Core/Card';
import ActionStatus from '@/components/Core/ActionStatus';
import Hooper from '@/components/Core/Hooper';
import { AdStatus, OrderStatus } from '@/constants';
import moment from 'moment';

export default {
  components: {
    Card,
    ActionStatus,
    Hooper
  },
  props: {
    nationalAds: { type: Object, default: null },
    isLoading: Boolean
  },
  data() {
    return {
      AdStatus,
      OrderStatus
    };
  },
  computed: {
    ads() {
      if (this.nationalAds && this.nationalAds.data) {
        return this.nationalAds.data;
      }
      return null;
    },
    carouselSettings() {
      let settings = {
        itemsToShow: 4
      };
      if (this.ads) {
        if (this.ads.length >= 4) {
          settings.infiniteScroll = true;
        }
      }
      return settings;
    }
  },
  methods: {
    onApproveOrderClick(ad) {
      this.$router.push({
        name: 'supplier.approvals',
        query: {
          filter: {
            refGroup: { 'order.ref': [ad.order.ref] }
          }
        }
      });
    },
    onApproveCreativeClick(ad) {
      this.$router.push({
        name: 'supplier.ads',
        query: {
          showCreativeDialog: true,
          filter: {
            supplierAdsFilter: {
              or: { ref: { like: ad.ref } }
            }
          }
        }
      });
    },
    onUploadVerficationClick(ad) {
      this.$router.push({
        name: 'supplier.verifications',
        query: {
          filter: {
            supplierVerificationsFilter: {
              or: { ref: { like: ad.ref } }
            }
          }
        }
      });
    },
    beforeRunDate(runDate) {
      return moment(new Date()).isBefore(runDate);
    },
    isCreativeApproved(item) {
      return (
        item.status === this.AdStatus.READY ||
        item.status === this.AdStatus.PENDING_VERIFICATION_APPROVAL ||
        item.status === this.AdStatus.VERIFICATION_REJECTED ||
        item.status === this.AdStatus.VERIFIED ||
        item.status === this.AdStatus.UNVERIFIED
      );
    }
  }
};
</script>
