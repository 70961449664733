<template>
  <edit-dialog
    :has-secondary="!isConfirming"
    :title="dialogConfig.title"
    :secondary-class="dialogConfig.secondaryClass"
    :primary-class="dialogConfig.primaryClass"
    :save-text="dialogConfig.saveText"
    :secondary-text="dialogConfig.secondaryText"
    :cancel-text="dialogConfig.cancelText"
    :is-saving="isLoading"
    width="50%"
    @save="onOverride"
    @close="onClose"
    @secondary="$emit('save')"
  >
    <div v-if="isConfirming" class="text-red text-center">
      <h2>
        Careful!
      </h2>
      Please make sure you know what statuses are accepted.
      <custom-el-select
        v-model="status"
        value-by-key
        class="mt-4 min-w-20"
        singular
        :options="AdStatuses"
        @change="status = $event"
      />
    </div>
    <div v-else class="text-center">
      Would you like to automatically update status or manually override?
    </div>
  </edit-dialog>
</template>

<script>
import { AdStatusInfo } from '@/constants';

import EditDialog from '@/components/Core/EditDialog';
import CustomElSelect from '@/components/Custom/CustomElSelect';

export default {
  components: {
    EditDialog,
    CustomElSelect
  },
  props: {
    isLoading: Boolean
  },
  data() {
    return {
      AdStatuses: Object.values(AdStatusInfo),
      isConfirming: false,
      status: ''
    };
  },
  computed: {
    dialogConfig() {
      return {
        title: this.isConfirming ? 'Change status' : 'Are you sure?',
        cancelText: this.isConfirming ? 'Cancel' : 'Close',
        secondaryText: 'Automatic',
        saveText: 'Override',
        primaryClass: 'button button-red hover:bg-dark-red',
        secondaryClass: 'button-blue'
      };
    }
  },
  methods: {
    onClose() {
      if (this.isConfirming) {
        this.isConfirming = false;
      } else {
        this.$emit('close');
      }
    },
    onOverride() {
      if (this.isConfirming) {
        this.$emit('override', this.status);
      } else {
        this.isConfirming = true;
      }
    }
  }
};
</script>
