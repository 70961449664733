<template>
  <iframe-v4-layout v-if="v4" id="v4-supplier-orders-table" :url="url" />
  <InsertionOrdersTable v-else />
</template>

<script>
import IframeV4Layout from '@/components/Core/IframeV4Layout';
import InsertionOrdersTable from '@/components/Supplier/Ads/InsertionOrdersTable';
import { getAuthorizationToken } from '@/utils/authorization';

export default {
  components: { InsertionOrdersTable, IframeV4Layout },
  computed: {
    v4() {
      return this.$store.getters['auth/feature']('v4-ads');
    },
    url() {
      const jwt = getAuthorizationToken();
      // Get filter from URL and convert to URI component to pass to iframe URL
      const filter = this.$route.query.filter;
      return (
        this.$apiUrl +
        '/supplier-v4/orders?jwt_token=' +
        jwt +
        (filter ? '&filter=' + encodeURIComponent(JSON.stringify(filter)) : '')
      );
    }
  }
};
</script>
