<template>
  <div class="reschedule-ad-picker">
    <inventory-query
      v-if="ad"
      :supplier-id="supplierId"
      :filter="{
        sku: ad.sku
      }"
      @data="onInventory"
    />

    <div v-if="!ad" class="mb-3">
      <el-select v-model="pickerMode">
        <el-option value="range" label="Range (Contiguous)" />
        <el-option value="single" label="Single Date (Non Contiguous)" />
      </el-select>
    </div>

    <div v-if="!ad || skuInventory">
      <available-flyte-picker
        v-if="isDateRange"
        :count="1"
        :config.sync="flytePickerConfig"
        :value="selectedDates"
        :insertion-dates="availableDates"
        :disable-hover="!ad"
        class="w-full"
        @input="changeDateRange"
        @date-render="onInventoryRenderDate"
      >
        <template #hover="{ date }">
          <booking-date-popover
            hide-selection
            :sku-date="getSkuDate(date.date)"
          />
        </template>
      </available-flyte-picker>

      <flyte-multi-picker
        v-else
        :value="selectedDates"
        :count="1"
        :config="multiPickerConfig"
        :disable-hover="!ad"
        @input="changeDate"
        @date-render="onInventoryRenderDate"
      >
        <template #hover="{ date }">
          <booking-date-popover
            hide-selection
            :sku-date="getSkuDate(date.date)"
          />
        </template>
      </flyte-multi-picker>
    </div>
    <div v-else>
      <loader-box :height="20" />
    </div>
  </div>
</template>

<script>
import { ScheduleTypeValue } from '@/constants';

import AvailableFlytePicker from '@/components/AdShop/AvailableFlytePicker';
import BookingDatePopover from '@/components/AdShop/Booking/BookingDatePopover';
import InventoryBookingMixin from '@/components/AdShop/Booking/InventoryBookingMixin';
import FlyteMultiPicker from '@/components/Core/FlytePicker/FlyteMultiPicker';

export default {
  components: {
    FlyteMultiPicker,
    BookingDatePopover,
    AvailableFlytePicker
  },
  mixins: [InventoryBookingMixin],
  props: {
    ad: {
      type: Object,
      default: null
    },
    supplierId: {
      type: String,
      default: null
    },
    loading: Boolean,
    allowAnyDate: Boolean
  },
  data() {
    return {
      skuInventory: null,
      flytePickerConfig: {
        mode: 'range',
        // The current start date may be any date in the past
        minDate: null
      },
      multiPickerConfig: {
        mode: 'single',
        minDate: null
      },
      pickerMode: 'range'
    };
  },

  computed: {
    canBookAnyDate() {
      return this.$can('pub_unrestricted_reschedule_ads');
    },

    selectedDates() {
      let selectedDate = this.dates[0];

      if (selectedDate) {
        return this.isDateRange
          ? [selectedDate.start_date, selectedDate.end_date]
          : [selectedDate.start_date];
      } else {
        return [];
      }
    },

    availableDates() {
      if (this.canBookAnyDate) {
        return null;
      } else {
        if (this.skuInventory) {
          return this.skuInventory.map(i => i.date);
        }

        return [];
      }
    },

    isDateRange() {
      if (this.ad) {
        return (
          this.ad.orderProduct.scheduleType.value ===
          ScheduleTypeValue.CONTIGUOUS
        );
      } else {
        return this.pickerMode === 'range';
      }
    }
  },

  methods: {
    changeDate(date) {
      let newValue = [
        {
          start_date: date[0],
          end_date: null
        }
      ];

      this.$emit('input', newValue);
      this.$emit('update:dates', newValue);
    },

    changeDateRange(dateRange) {
      let newValue = [
        {
          start_date: dateRange[0],
          end_date: dateRange[1]
        }
      ];

      this.$emit('input', newValue);
      this.$emit('update:dates', newValue);
    }
  }
};
</script>
