<template>
  <div class="payment-method-approval text-dark-gray">
    <div class="description">
      This buyer has an order pending approval, in which they’ve requested to
      pay by invoice. If you approve this buyer to pay by invoice, they will not
      be required to enter credit card information, and you will be responsible
      for collecting and recording payment.
    </div>

    <div class="margin-top-xl font-bold">
      Approving payment by invoice will also approve the pending order.
    </div>

    <div class="invoice-info-box box-shadow-lg margin-top-xl">
      <h3>Invoice request</h3>

      <div class="flex contact-info justify-start mt-6">
        <contact-info :contact="primaryContact" title="Primary Contact" />
        <contact-info
          v-if="billingContact"
          :contact="billingContact"
          title="Billing Contact"
          class="ml-5"
        />
      </div>
      <div class="invoice-line margin-top">
        <label class="label">Message:</label>
        <span class="value">
          {{
            order.invoice_message ||
              'Please approve my request to pay by Invoice'
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ok } from '@/vendor/icons';
import ContactInfo from '@/components/Core/ContactInfo';

export default {
  components: { ContactInfo },
  props: {
    order: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // Icons
      ok
    };
  },

  computed: {
    primaryContact() {
      return this.campaign
        ? this.campaign.primaryContact
        : this.order.customer.buyerPrimaryContact;
    },
    billingContact() {
      return this.campaign
        ? this.campaign.billingContact
        : this.order.customer.buyerBillingContact;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.invoice-info-box {
  border: 1px solid $color-gray;
  padding: 1.25em 1.5em;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
}

.invoice-line {
  .label {
    display: inline-block;
    font-weight: bold;
    width: 6em;
  }
}
</style>
