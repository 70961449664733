<template>
  <confirm-dialog
    title="Copy Ad Serving Code"
    disabled
    width="80em"
    @close="$emit('close')"
  >
    <div class="text-base">
      <el-tabs v-model="selectedTab">
        <el-tab-pane
          v-if="ad.orderProduct.is_digital_content"
          :label="TagTypes.PLATFORM.label"
          :name="TagTypes.PLATFORM.value"
        >
          <ad-serving-recommendation
            :ad="ad"
            :tag-type="TagTypes.PLATFORM"
            class="mt-5"
            @tab="selectedTab = $event"
          />

          <transition name="fade" mode="out-in">
            <el-form v-if="showPlatformScriptTag">
              <digital-script-tag-settings
                hide-label
                :property="{ id: ad.orderProduct.property_id }"
              />
            </el-form>
            <div v-else class="my-8 text-center">
              <div class="font-bold text-lg">
                <div>
                  You have already installed your script tag on your website.
                </div>
                <div class="text-green">
                  No action is required to run this ad.
                </div>
              </div>
              <div class="mt-5">
                <a @click="showPlatformScriptTag = true">
                  View Script Tag Installation Instructions
                </a>
              </div>
            </div>
          </transition>
        </el-tab-pane>
        <el-tab-pane
          :label="TagTypes.THIRD_PARTY.label"
          :name="TagTypes.THIRD_PARTY.value"
        >
          <ad-serving-recommendation
            :ad="ad"
            :tag-type="TagTypes.THIRD_PARTY"
            class="mt-5"
            @tab="selectedTab = $event"
          />

          <template v-if="ad.is_synced_to_digital_vendor">
            <div class="my-5">
              Copy the ad serving code below and paste it into the corresponding
              ad creative (HTML/Javascript field) in your ad server.
            </div>
            <script-code copy class="text-sm">
              <slot>{{ ad.servingTags.thirdParty }}</slot>
            </script-code>
          </template>
          <template v-else>
            <el-alert
              type="error"
              title="Alert"
              class="mt-10"
              :closable="false"
            >
              This ad requires additional review. Please contact your Flytedesk
              account representative.
            </el-alert>
          </template>
        </el-tab-pane>
        <el-tab-pane
          :label="TagTypes.STATIC.label"
          :name="TagTypes.STATIC.value"
        >
          <ad-serving-recommendation
            :ad="ad"
            :tag-type="TagTypes.STATIC"
            class="mt-5"
            @tab="selectedTab = $event"
          />

          <template v-if="ad.is_synced_to_digital_vendor">
            <div class="my-5">
              Copy the ad serving code below and paste it into your email
              template or campaign.
            </div>
            <div class="mb-5">
              <div class="mb-3">Select your email service provider</div>
              <el-select
                v-model="selectedEmailServer"
                placeholder="Select Provider..."
              >
                <el-option
                  v-for="emailServer in EmailServers"
                  :key="emailServer.value"
                  :label="emailServer.label"
                  :value="emailServer"
                />
              </el-select>
            </div>
            <script-code copy class="text-sm">
              <slot>{{ staticCode }}</slot>
            </script-code>
          </template>
          <template v-else>
            <el-alert
              type="error"
              title="Alert"
              class="mt-10"
              :closable="false"
            >
              This ad requires additional review. Please contact your Flytedesk
              account representative.
            </el-alert>
          </template>
        </el-tab-pane>
      </el-tabs>
    </div>
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import ScriptCode from '@/components/Supplier/Mediakit/Common/ScriptCode';
import { EmailServers, TagTypes } from '@/constants/digital';
import AdServingRecommendation from '@/components/Core/AdServingRecommendation';
import DigitalScriptTagSettings from '@/components/Supplier/Mediakit/Common/DigitalScriptTagSettings';

export default {
  components: {
    DigitalScriptTagSettings,
    AdServingRecommendation,
    ScriptCode,
    ConfirmDialog
  },
  props: {
    ad: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedTab: this.ad.tagType || TagTypes.PLATFORM.value,
      selectedEmailServer: null,
      showPlatformScriptTag: !this.ad.orderProduct.is_script_tag_active,

      // Consts
      EmailServers,
      TagTypes
    };
  },
  computed: {
    staticCode() {
      let code = this.ad.servingTags.static;

      if (this.selectedEmailServer) {
        code = code.replace(
          /{{CACHE_BUSTER}}/g,
          this.selectedEmailServer.cacheBuster || ''
        );
      }

      return code;
    }
  }
};
</script>
