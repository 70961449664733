<template>
  <div class="ad-serving-recommendation">
    <el-alert
      v-if="isRecommended"
      type="success"
      title="Recommended"
      :closable="false"
    >
      Use this tag to place this Ad #{{ ad.ref }}
    </el-alert>
    <el-alert v-else type="error" title="Not Recommended" :closable="false">
      You should use the
      <a @click="goToTag">{{ recommendedTagType.label }}</a>
      to place Ad #{{ ad.ref }}
    </el-alert>
  </div>
</template>
<script>
import { TagTypes } from '@/constants/digital';

export default {
  props: {
    ad: {
      type: Object,
      required: true
    },
    tagType: {
      type: Object,
      required: true
    }
  },
  computed: {
    isRecommended() {
      return this.ad.tagType === this.tagType.value;
    },
    recommendedTagType() {
      return TagTypes[this.ad.tagType] || TagTypes.PLATFORM;
    }
  },
  methods: {
    goToTag() {
      this.$emit('tab', this.recommendedTagType.value);
    }
  }
};
</script>
