<template>
  <div class="page-content text-dark-gray">
    <the-page-header>
      <template slot="title">Settings</template>
      <template slot="subtitle">
        Manage your user account and organization information
      </template>
    </the-page-header>
    <div class="flex flex-row justify-between">
      <div class="flex-1 mr-10">
        <el-tabs
          :value="$route.name"
          @tab-click="$router.push({ name: $event.name })"
        >
          <el-tab-pane
            :name="`${tabPrefix}.settings.account`"
            label="Account"
          />
          <el-tab-pane
            v-if="isSupplier"
            :name="`${tabPrefix}.settings.notifications`"
            label="Notifications"
          />
          <template v-if="!isInfluencer || $can('takeover_suppliers')">
            <el-tab-pane
              :name="`${tabPrefix}.settings.organization`"
              label="Organization"
            />
            <el-tab-pane :name="`${tabPrefix}.settings.team`" label="Team" />
            <el-tab-pane
              v-if="isSupplier && $feature('subscriptions-page')"
              :name="`${tabPrefix}.settings.subscription`"
              label="Subscriptions"
            />
          </template>
        </el-tabs>
        <transition name="fade" mode="out-in">
          <router-view class="mt-10" />
        </transition>
      </div>
      <contact-card v-if="flytedeskRep" class="mt-10" :contact="flytedeskRep" />
    </div>
  </div>
</template>

<script>
import ContactCard from '@/components/Core/ContactCard';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

export default {
  components: {
    ContactCard,
    ThePageHeader
  },
  computed: {
    isInfluencer() {
      return this.$store.getters['auth/isInfluencer'];
    },
    flytedeskRep() {
      if (this.$store.getters['auth/isSupplier']) {
        return this.$store.getters['auth/supplier'].repUser;
      }
      if (this.$store.getters['auth/isBuyer']) {
        return this.$store.getters['auth/buyer'].repUser;
      }
      return null;
    },
    isSupplier() {
      return this.$store.getters['auth/isSupplier'];
    },
    tabPrefix() {
      return this.isSupplier ? 'supplier' : 'buyer';
    }
  }
};
</script>
