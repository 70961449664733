<template>
  <div class="page-content">
    <supplier-dashboard-query fetch-policy="network-only">
      <template v-slot="{ isLoading, data }">
        <the-page-header>
          <div slot="title" class="flex items-center mt-6">
            Dashboard
          </div>
          <template #subtitle>
            An overview of activity and pending actions in your flytedesk
            account.
          </template>
        </the-page-header>
        <supplier-dashboard-pending
          :is-loading="isLoading"
          :pending-actions="data ? data.summaryStats : []"
          class="w-full"
        />
        <supplier-dashboard-national-ads
          :is-loading="isLoading"
          :national-ads="data ? data.ads : null"
          class="w-full mt-6"
        />
      </template>
    </supplier-dashboard-query>
  </div>
</template>

<script>
import SupplierDashboardPending from './components/SupplierDashboardPending';
import SupplierDashboardNationalAds from './components/SupplierDashboardNationalAds';
import { SupplierDashboardQuery } from '@/components/Queries';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

import { bellAlt as bellAltIcon } from '@/vendor/icons';

export default {
  components: {
    SupplierDashboardPending,
    SupplierDashboardNationalAds,
    SupplierDashboardQuery,
    ThePageHeader
  },

  data() {
    return {
      bellAltIcon,
      notificationText:
        'Thanks for checking out our dashboard beta! Let us know what you think, and what additional information you’d like to see here.'
    };
  }
};
</script>
