<template>
  <confirm-dialog
    :title="`Ad ${ad.ref} History`"
    disabled
    cancel-text="Close"
    @close="$emit('close')"
  >
    <div class="flex">
      <div class="min-w-48">
        <div class="p-3 shadow-right">
          <div class="font-bold">
            Ad Details
          </div>
          <ad-column :ad="ad" hide-details />
        </div>
      </div>
      <div class="flex-grow min-w-40 ml-8">
        <ad-history :ad="ad" />
      </div>
    </div>
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import AdHistory from '@/components/Supplier/Ads/AdHistory';
import AdColumn from '@/components/Core/Table/Columns/AdColumn';

export default {
  components: {
    AdColumn,
    AdHistory,
    ConfirmDialog
  },
  props: {
    ad: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>
