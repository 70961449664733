<template>
  <div class="insertion-orders">
    <supplier-orders-query
      :loading.sync="isLoadingOrders"
      @data="onReceivedOrders"
    />

    <supplier-orders-search-tools-query
      @data="searchTools = $event.supplier.ordersData.searchTools"
    />

    <supplier-reps-query
      :loading.sync="isLoadingReps"
      @data="availableReps = $event.supplier.buyerReps"
    />

    <div class="flex flex-row border-top">
      <div class="flex-shrink-0">
        <collapsible-search-group-sidebar
          v-model="searchInput"
          :search-tools="searchTools || []"
          :is-loading="!searchTools"
          :filter="pager.filter"
          @search="pager = { ...pager, ...$event }"
        />
      </div>
      <div
        class="flex flex-1 flex-col p-8 overflow-x-auto min-w-40 border-left"
      >
        <supplier-orders-mutation>
          <template v-slot="{ isSaving, exportOrders }">
            <filter-toolbar
              class="items-end"
              placeholder="Search Orders, Customers, etc."
              :is-exporting="isSaving"
              :value="pager.filter"
              @input="pager = { ...pager, filter: $event }"
              @export="onExport(exportOrders)"
            />
          </template>
        </supplier-orders-mutation>

        <div class="relative mt-3">
          <el-table
            :data="(!isLoadingOrders && orders) || []"
            border
            class="el-table-slim"
            :row-class-name="tableRowClassName"
          >
            <table-empty-slot
              slot="empty"
              :is-loading="isLoadingOrders"
              :has-data="!!orders"
              :empty-message="emptyFilterMessage"
              error-message="There was a problem loading your orders. Please try again later."
            />

            <el-table-column label="Status" align="center" width="100">
              <status-icon
                slot-scope="{ row }"
                :icons="OrderStatusIcons"
                :status="row.status"
                :campaign="row.campaign"
              />
            </el-table-column>

            <el-table-column
              prop="created_at"
              label="Created Date"
              align="center"
              width="140"
            >
              <template v-slot="{ row }">
                {{ row.created_at | date }}
              </template>
            </el-table-column>

            <el-table-column
              prop="start_date"
              label="Run Date(s)"
              align="center"
              width="110"
            >
              <date-column
                slot-scope="{ row }"
                :start-date="row.start_date"
                :end-date="row.end_date"
              />
            </el-table-column>

            <el-table-column label="IO" align="center" width="100">
              <supplier-io-column
                slot-scope="{ row }"
                :campaign="row.campaign"
                :order="row"
                @review="onReviewOrder(row)"
              />
            </el-table-column>

            <el-table-column label="Customer">
              <customer-column
                slot-scope="{ row }"
                :customer="row.customer"
                :campaign="row.campaign"
              />
            </el-table-column>

            <el-table-column label="Rep" width="300">
              <reps-column
                v-if="!isLoadingReps"
                slot-scope="{ row }"
                :available-reps="availableReps"
                :order="row"
              />
            </el-table-column>

            <el-table-column label="Comments" width="150" align="center">
              <template v-slot="{ row }">
                <supplier-notes-column
                  start-on-order
                  :order="row"
                  :start-open="
                    showNoteDialog && $route.params.entity_id === row.id
                  "
                  @close="showNoteDialog = null"
                />
              </template>
            </el-table-column>

            <el-table-column label="Action" align="center" width="100">
              <template slot-scope="{ row }">
                <supplier-orders-mutation>
                  <template
                    v-slot="{
                      isSaving,
                      cancelAllLineItems,
                      resumeAllLineItems,
                      resetApprovalStatus,
                      deleteOrder
                    }"
                  >
                    <template v-if="!isSaving">
                      <action-menu
                        :actions="actions(row)"
                        @cancel="orderToCancel = row"
                        @resume="orderToResume = row"
                        @review="onReviewOrder(row)"
                        @reset="resetApprovalStatus(row.id)"
                        @delete="onDeleteOrder(row)"
                        @approval="onApproveAds(row)"
                      >
                        <io-download-link
                          v-if="row.approved_ads_count > 0"
                          :order="row"
                          menu-item
                        />
                      </action-menu>

                      <cancel-order-dialog
                        v-if="orderToCancel === row"
                        :order="row"
                        @close="orderToCancel = null"
                      />

                      <resume-order-dialog
                        v-if="orderToResume === row"
                        :order="row"
                        @close="orderToResume = null"
                      />

                      <confirm-dialog
                        v-if="showDeleteOrderDialog"
                        :title="'Permanently Delete ' + selectedOrder.ref"
                        :is-saving="isSaving"
                        confirm-class="button-red"
                        confirm-text="Delete Order"
                        @close="showDeleteOrderDialog = false"
                        @confirm="onConfirmDelete(deleteOrder)"
                      >
                        Are you sure you want to permanently delete order #{{
                          selectedOrder.ref
                        }}? This will also delete any associated ads and line
                        items.
                      </confirm-dialog>
                    </template>

                    <loading-button v-else class="py-2 px-6" />
                  </template>
                </supplier-orders-mutation>
              </template>
            </el-table-column>
          </el-table>

          <pagination
            v-if="paginatorInfo"
            :info="paginatorInfo"
            :pager.sync="pager"
            class="margin-top"
          />
        </div>
      </div>
    </div>
    <national-order-review-dialog
      v-if="showNationalOrderReviewDialog && $can('pub_accept_or_reject_order')"
      :order="selectedOrder"
      @close="showNationalOrderReviewDialog = false"
    />
    <local-order-review-dialog
      v-if="showLocalOrderReviewDialog && $can('pub_change_invoice_settings')"
      :order="selectedOrder"
      :campaign="selectedOrder.campaign"
      @close="showLocalOrderReviewDialog = false"
    />
  </div>
</template>

<script>
import {
  CampaignType,
  OrderApprovalStatus,
  OrderStatus,
  OrderStatusIcons,
  OrderStatusValues
} from '@/constants';
import { date as formatDate } from '@/utils/filters';
import moment from 'moment';
import { filterDateBetween } from '@/utils/sortFilter';
import {
  bubble as bubbleIcon,
  ellipsisV as ellipsisIcon,
  filePdf
} from '@/vendor/icons';

import ActionMenu from '@/components/Core/ActionMenu';
import CancelOrderDialog from '@/components/Supplier/Ads/CancelOrderDialog';
import CollapsibleSearchGroupSidebar from '@/components/Core/CollapsibleSearchGroupSidebar';
import FilterToolbar from '@/components/Core/Table/FilterToolbar';
import IoDownloadLink from '@/components/Order/IoDownloadLink';
import LoadingButton from '@/components/Core/Loading/LoadingButton';
import LocalOrderReviewDialog from '@/components/Supplier/Ads/LocalOrderReviewDialog';
import NationalOrderReviewDialog from '@/components/Supplier/Ads/NationalOrderReviewDialog';
import Pagination from '@/components/Core/Table/Pagination';
import SupplierNotesColumn from '@/components/Supplier/Ads/SupplierNotesColumn';
import SupplierOrdersSearchToolsQuery from '@/components/Queries/Supplier/Ads/SupplierOrdersSearchToolsQuery';
import RepsColumn from '@/components/Supplier/Common/RepsColumn';
import ResumeOrderDialog from '@/components/Supplier/Ads/ResumeOrderDialog';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import { SupplierOrdersMutation } from '@/components/Mutations';
import { SupplierOrdersQuery, SupplierRepsQuery } from '@/components/Queries';
import {
  CustomerColumn,
  DateColumn,
  SupplierIoColumn
} from '@/components/Core/Table/Columns';
import { download } from '@/vendor/download';
import ConfirmDialog from '@/components/Core/ConfirmDialog';

export default {
  components: {
    ConfirmDialog,
    ResumeOrderDialog,
    CancelOrderDialog,
    DateColumn,
    ActionMenu,
    CollapsibleSearchGroupSidebar,
    CustomerColumn,
    FilterToolbar,
    IoDownloadLink,
    LoadingButton,
    LocalOrderReviewDialog,
    NationalOrderReviewDialog,
    Pagination,
    SupplierIoColumn,
    SupplierNotesColumn,
    SupplierOrdersMutation,
    SupplierOrdersQuery,
    SupplierOrdersSearchToolsQuery,
    SupplierRepsQuery,
    RepsColumn,
    StatusIcon,
    TableEmptySlot
  },

  data() {
    return {
      isLoadingOrders: false,
      paginatorInfo: null,
      searchTools: null,
      OrderStatus,
      OrderStatusIcons,
      OrderStatusValues,
      orders: null,
      searchInput: {},
      availableReps: null,
      isLoadingReps: true,
      showLocalOrderReviewDialog: false,
      showNationalOrderReviewDialog: false,
      showDeleteOrderDialog: false,
      orderToCancel: null,
      orderToResume: null,
      selectedOrder: null,
      showNoteDialog: null,

      // Icons
      bubbleIcon,
      ellipsisIcon,
      filePdf
    };
  },

  computed: {
    dateRangeFilter() {
      return this.pager.filter.filterDateRange;
    },
    pager: {
      get() {
        return this.$store.getters['orders/pager'];
      },
      set(value) {
        this.$store.commit('orders/setPager', value);
      }
    },
    emptyFilterMessage() {
      if (this.dateRangeFilter) {
        let startDate = this.dateRangeFilter['>='];
        let endDate = this.dateRangeFilter['<='];

        let str =
          "You don't have any Orders running between " + formatDate(startDate);

        if (endDate) {
          str += ' and ' + formatDate(endDate);
        } else {
          str += ' and the end of time';
        }

        return str;
      } else {
        return "You don't have any Orders :(";
      }
    }
  },

  created() {
    this.pager = {
      ...this.pager,
      filter: this.getDefaultFilter(),
      sort: [{ column: 'start_date', order: 'desc' }]
    };

    let dates = {};
    let filters = {};

    if (this.pager.filter.status) {
      filters['Status'] = {
        searchValue: {
          status: this.pager.filter.status
        },
        value: Array.isArray(this.pager.filter.status)
          ? this.pager.filter.status
          : [this.pager.filter.status]
      };
    }

    if (this.pager.filter.filterDateRange) {
      dates['Run Date Calendar'] = {
        value: this.pager.filter.filterDateRange,
        searchValue: {
          filterDateRange: this.pager.filter.filterDateRange
        }
      };
    }

    this.searchInput = {
      dates,
      filters
    };
  },
  mounted() {
    this.resolveActiveOrder();
  },
  methods: {
    getDefaultFilter() {
      if (
        this.$route.query &&
        this.$route.query.filter &&
        typeof this.$route.query.filter !== 'string'
      ) {
        return { ...this.$route.query.filter };
      } else {
        return {
          filterDateRange: filterDateBetween(moment(), moment().add(14, 'days'))
        };
      }
    },
    actions(order) {
      let actionsArray = [];
      const isLocal =
        order.campaign && order.campaign.type === CampaignType.LOCAL;

      const hasPendingAds = order.pending_ads_count > 0;

      // conditionals on actions
      const canCancel =
        order.status !== OrderStatus.CANCELED &&
        (isLocal
          ? this.$can('pub_change_order_status')
          : this.$can('cancel_national_orders'));

      const canDelete =
        order.status === OrderStatus.CANCELED &&
        (isLocal
          ? this.$can('pub_delete_order')
          : this.$can('cancel_national_orders'));

      const canResume = isLocal
        ? this.$can('pub_change_order_status')
        : this.$can('cancel_national_orders');

      const canReview =
        order.approval_status === OrderApprovalStatus.PENDING &&
        order.approved_ads_count > 0 &&
        this.$can('pub_accept_or_reject_order');

      const canReset =
        !isLocal &&
        order.status !== OrderStatus.PENDING &&
        this.$can('reset_order_approval_status');

      // push allowed actions to array for rendering
      if (hasPendingAds) {
        actionsArray.push({
          label: 'Approve Ads',
          value: 'approval',
          class: 'text-blue'
        });
      }

      if (canCancel) {
        actionsArray.push({
          label: 'Cancel All Ads',
          value: 'cancel'
        });
      }

      if (canResume) {
        actionsArray.push({ label: 'Resume All Ads', value: 'resume' });
      }

      if (canReview) {
        actionsArray.push({ label: 'Review', value: 'review' });
      }

      if (canReset) {
        actionsArray.push({ label: 'Reset Approval Status', value: 'reset' });
      }

      if (canDelete) {
        actionsArray.push({
          label: 'Delete',
          value: 'delete',
          class: 'text-red'
        });
      }
      return actionsArray;
    },
    onApproveAds(order) {
      this.$router.push({
        name: 'supplier.approvals',
        query: {
          filter: {
            refGroup: { 'order.ref': [order.ref] }
          }
        }
      });
    },
    onReviewOrder(order) {
      this.selectedOrder = order;
      if (order.campaign.type === CampaignType.NATIONAL) {
        this.showNationalOrderReviewDialog = true;
      } else {
        this.showLocalOrderReviewDialog = true;
      }
    },
    async onExport(exportOrders) {
      const data = await exportOrders(this.pager.filter);

      download(data.mutateOrder.exportOrders, 'Orders.csv');
    },
    tableRowClassName({ row: order }) {
      if (order.status !== OrderStatus.CANCELED) {
        if (order.approval_status === OrderApprovalStatus.CHANGES_REQUESTED) {
          return 'row-warning';
        } else if (order.approval_status === OrderApprovalStatus.PENDING) {
          return 'row-error';
        }
      }

      return '';
    },
    resolveActiveOrder() {
      const params = this.$route.params;

      if (params && this.orders) {
        if (params.showNoteDialog) {
          this.showNoteDialog = true;
        } else if (params.ad) {
          this.onReviewOrder(
            this.orders.find(o => o.id === params.ad.order.id)
          );
        }
      }
    },
    onReceivedOrders({ supplier: { ordersData } }) {
      this.orders = ordersData.orders.data;
      this.paginatorInfo = ordersData.orders.paginatorInfo;

      this.resolveActiveOrder();
    },
    onDeleteOrder(order) {
      this.showDeleteOrderDialog = true;
      this.selectedOrder = order;
    },
    async onConfirmDelete(deleteOrder) {
      await deleteOrder(this.selectedOrder.id);
      this.showDeleteOrderDialog = false;
    }
  }
};
</script>
