<template>
  <edit-dialog
    :is-saving="isLoading"
    has-secondary
    :hide-primary="!canCancelLineItem"
    :title="dialogConfig.title"
    :secondary-class="dialogConfig.secondaryClass"
    :primary-class="dialogConfig.primaryClass"
    :save-text="dialogConfig.saveText"
    :secondary-text="dialogConfig.secondaryText"
    :cancel-text="dialogConfig.cancelText"
    width="60%"
    @save="$emit('cancel-both')"
    @close="$emit('close')"
    @secondary="$emit('cancel-ad')"
  >
    <div v-if="isPaidbyCreditCard" class="text-center">
      The billing line item for this ad was paid by credit card and cannot be
      cancelled.
      <br />
      <br />
      Please reach out to your flytedesk rep found on your
      <router-link :to="{ name: 'supplier.settings' }">
        Account page
      </router-link>
      for help issuing a credit.
    </div>
    <div v-else class="text-center">
      <div>
        <div>
          The billing line item related to <b>ad #{{ adRef }}</b> has the
          current status
        </div>
        <div class="text-base mt-2 flex justify-center">
          <div class="font-bold">
            {{ ad.orderLineItem.supplier_price | currency }}
          </div>
          <status-icon
            class="ml-4"
            inline
            :status="ad.orderLineItem.status"
            :icons="OrderLineItemStatusIcons"
          />
        </div>
      </div>

      <div class="mt-5">
        <template v-if="canCancelLineItem">
          Would you also like to cancel both the ad and the billing line item?
        </template>
        <template v-else
          >The billing line item has been invoiced for
          <b>{{ ad.orderLineItem.invoiced_amount | currency }}</b> and cannot be
          canceled.<br />To cancel the line item first void or delete the
          invoice
        </template>
      </div>
    </div>
  </edit-dialog>
</template>

<script>
import EditDialog from '@/components/Core/EditDialog';
import { OrderLineItemStatusIcons, OrderLineItemStatusKeys } from '@/constants';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';

export default {
  components: {
    StatusIcon,
    EditDialog
  },
  props: {
    isLoading: Boolean,
    ad: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      OrderLineItemStatusIcons
    };
  },
  computed: {
    adRef() {
      return this.ad ? this.ad.ref : '';
    },
    canCancelLineItem() {
      return (
        !this.isPaidbyCreditCard &&
        this.ad &&
        this.ad.orderLineItem.invoiced_amount <= 0
      );
    },
    dialogConfig() {
      return {
        title:
          this.ad && this.ad.ref
            ? `Cancel Ad #${this.ad.ref}`
            : 'Cancel this Ad',
        cancelText: 'Never mind',
        secondaryText: 'Cancel Ad Only',
        saveText: 'Cancel Both',
        primaryClass: 'button button-red hover:bg-dark-red',
        secondaryClass: this.isPaidbyCreditCard
          ? 'button button-red hover:bg-dark-red'
          : 'button-white'
      };
    },
    isPaidbyCreditCard() {
      if (!this.ad) return false;
      const isPaid =
        this.ad.orderLineItem &&
        this.ad.orderLineItem.status &&
        this.ad.orderLineItem.status === OrderLineItemStatusKeys.INVOICED;

      const order =
        this.ad.order || (this.ad.orderLineItem && this.ad.orderLineItem.order);
      const isByCreditCard =
        order && order.paymentMethod && order.paymentMethod.is_credit_card;

      return isPaid && isByCreditCard;
    },
    lineItemStatus() {
      const status =
        this.ad && this.ad.orderLineItem && this.ad.orderLineItem.status;
      if (status) {
        return this.OrderLineItemStatusIcons[status];
      }
      return {};
    }
  }
};
</script>
