<template>
  <div id="the-supplier-dashboard" class="page-content">
    <the-page-header>
      <template slot="title">
        Orders
      </template>
      <template slot="subtitle">
        <span v-if="isOnAdsPane">
          View all of your ads. From here you can download, approve, reject, and
          reschedule ads as needed.
        </span>
        <span v-else>
          View all of your orders here. Manage approvals, download insertion
          orders, and see upcoming insertions.
        </span>
      </template>
    </the-page-header>

    <el-tabs
      :value="$route.name"
      @tab-click="
        $event.name !== $route.name && $router.push({ name: $event.name })
      "
    >
      <el-tab-pane key="tab-label" disabled label="View by:" />
      <el-tab-pane
        v-if="$feature('ad-approval')"
        key="tab-dashboard-approvals"
        name="supplier.approvals"
      >
        <template v-slot:label>
          <div class="approvals-tab">
            <div class="label">Approvals</div>
            <div v-if="pendingAdCount > 0" class="new">
              {{ pendingAdCount }}
            </div>
          </div>
        </template>
      </el-tab-pane>
      <el-tab-pane key="tab-dashboard-ads" name="supplier.ads" label="Ads" />
      <el-tab-pane
        key="tab-dashboard-orders"
        name="supplier.orders"
        label="Insertion Orders"
      />
    </el-tabs>

    <transition name="fade" mode="out-in">
      <router-view class="mt-8" />
    </transition>
  </div>
</template>

<script>
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

export default {
  components: {
    ThePageHeader
  },

  computed: {
    pendingAdCount() {
      return this.$store.getters['auth/supplier'].pending_approval_ads_count;
    },
    isOnAdsPane() {
      return this.$route.name === 'supplier.ads';
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/scss/variables.scss';
.approvals-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .label {
    font-size: 14px;
  }
  .new {
    display: flex;
    align-items: center;
    padding: 0.05rem 0.5rem;
    height: 1rem;
    font-size: 10px;
    line-height: 1;
    background-color: $color-dark-red;
    color: #fff;
    border-radius: 0.5rem;
    margin-left: 0.5em;
  }
}
</style>
