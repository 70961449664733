<template>
  <supplier-orders-mutation @done="$emit('close')">
    <template v-slot="{ isSaving, resumeAllLineItems }">
      <confirm-dialog
        :title="'Resume Order ' + order.ref"
        :is-saving="isSaving"
        confirm-text="Resume Ads"
        confirm-class="button-green"
        :cancel-text="!ads || canceledAds.length > 0 ? 'Nevermind' : 'Got it!'"
        :disabled="!ads || !canceledAds.length"
        @confirm="onResume(resumeAllLineItems)"
        @close="$emit('close')"
      >
        <div>
          <supplier-ads-query
            :pager="pager"
            :options="{
              fetchPolicy: 'network-only'
            }"
            @data="ads = $event.supplier.adsData.ads.data"
          />

          <template v-if="!ads">
            <loader-box :height="20" />
          </template>
          <template v-else>
            <div class="mt-3">
              <template v-if="canceledAds.length > 0">
                <div>
                  You're about to resume {{ canceledAds.length }} canceled ads
                  in order {{ order.ref }}.
                </div>
                <div class="mt-3">
                  <div>
                    The following ads and their billing line items will be
                    resumed
                  </div>
                  <div class="mt-1 ml-3 max-h-10 overflow-scroll-y">
                    <div
                      v-for="ad in canceledAds"
                      :key="ad.id"
                      class="flex items-center "
                    >
                      <div class="font-bold">{{ ad.ref }}</div>
                      <div class="ml-1 text-dark-silver">{{ ad.sku }}</div>
                      <div class="ml-1">{{ ad.start_date | date }}</div>
                      <template v-if="showEndDate(ad)">
                        to <span>{{ ad.end_date | date }}</span>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                There are no canceled ads in this order.
              </template>
            </div>
          </template>
        </div>
      </confirm-dialog>
    </template>
  </supplier-orders-mutation>
</template>

<script>
import moment from 'moment';
import { AdStatus } from '@/constants';
import { SupplierOrdersMutation } from '@/components/Mutations';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import SupplierAdsQuery from '@/components/Queries/Supplier/Ads/SupplierAdsQuery';

export default {
  components: {
    SupplierAdsQuery,
    ConfirmDialog,
    SupplierOrdersMutation
  },
  props: {
    order: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // Data
      ads: null
    };
  },
  computed: {
    canceledAds() {
      return this.ads.filter(a => a.status === AdStatus.CANCELED);
    },
    pager() {
      return {
        filter: {
          'order.id': this.order.id
        },
        perPage: 99999
      };
    }
  },
  methods: {
    showEndDate(ad) {
      return moment(ad.start_date).diff(ad.end_date, 'days') > 0;
    },
    async onResume(resumeAllLineItems) {
      await resumeAllLineItems(this.order);

      this.$message.success(
        `${this.canceledAds.length} ads were successfully resumed in order ${this.order.ref}!`
      );

      this.$emit('close');
    }
  }
};
</script>
