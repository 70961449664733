<template>
  <div class="ad-history">
    <supplier-ad-history-query
      :ad="ad"
      :loading.sync="isLoading"
      @data="
        history = $event.ad ? $event.ad.history : $event.supplier.ad.history
      "
    />

    <div v-if="history">
      <div
        v-for="item in history"
        :key="'ad-history-item-' + item.id"
        class="ad-history-item p-3 flex"
      >
        <div class="flex-grow">
          <ad-history-change
            v-for="change in item.changes"
            :key="'ad-history-change-' + item.id + change.name"
            :change="change"
            class="my-2"
          />
        </div>

        <div class="text-right">
          <div>{{ item.user.name }}</div>
          <div>{{ item.user.email }}</div>
          <div class="text-dark-silver">{{ item.account }}</div>
          <div class="text-dark-silver text-sm">
            {{ item.date | datetime }}
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isLoading">
      <loader-box height="30" />
    </div>
    <div v-else>
      <el-alert type="error" title="" content="Failed to load ad history" />
    </div>
  </div>
</template>

<script>
import SupplierAdHistoryQuery from '@/components/Queries/Supplier/Ads/SupplierAdHistoryQuery';
import AdHistoryChange from '@/components/Supplier/Ads/AdHistoryChange';

export default {
  components: {
    AdHistoryChange,
    SupplierAdHistoryQuery
  },

  props: {
    ad: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // State
      history: null,
      isLoading: false
    };
  },

  computed: {},

  methods: {}
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.ad-history-item {
  &:nth-child(even) {
    background: $color-silver;
  }
}
</style>
