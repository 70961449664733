<template>
  <section v-if="isComplete" class="flex items-center text-green">
    <icon :icon="okCircledIcon" />
    <div class="ml-4">{{ completeText }}</div>
  </section>
  <section v-else-if="isDisabled" class="flex items-center text-dark-silver">
    <icon :icon="circleIcon" />
    <div class="ml-4">{{ disabledText }}</div>
  </section>
  <section
    v-else
    class="flex items-center text-blue cursor-pointer"
    @click="$emit('click', $event)"
  >
    <icon class="rotate-270" :icon="arrowCircleODownIcon" />
    <div class="ml-4">{{ actionText }}</div>
  </section>
</template>

<script>
import {
  arrowCircleODown as arrowCircleODownIcon,
  circle as circleIcon,
  okCircled as okCircledIcon
} from '@/vendor/icons';

export default {
  props: {
    completeText: { type: String, default: 'Complete' },
    disabledText: { type: String, default: 'Disabled' },
    actionText: { type: String, default: 'Approve' },
    isComplete: Boolean,
    isDisabled: Boolean,
    action: { type: Function, default: null }
  },
  data() {
    return { okCircledIcon, arrowCircleODownIcon, circleIcon };
  }
};
</script>
